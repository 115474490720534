import React, { useRef, useEffect } from "react";
import BannerVideo from "../../images/sirius_video_dubai.mp4";

const HomeBannerImage = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const handleTimeUpdate = () => {
            const video = videoRef.current;
            const stopTime = 42; // Stop at 5 seconds, for example

            if (video.currentTime >= stopTime) {
                video.pause();
                video.currentTime = stopTime; // Keep it at the stop time
            }
        };

        const video = videoRef.current;
        if (video) {
            video.addEventListener("timeupdate", handleTimeUpdate);
        }

        return () => {
            if (video) {
                video.removeEventListener("timeupdate", handleTimeUpdate);
            }
        };
    }, []);

    return (
        <video
            src={BannerVideo}
            alt="banner-img"
            className="banner-img"
            autoPlay
            playsInline
            muted
            ref={videoRef}
        />
    );
};

export default HomeBannerImage;
